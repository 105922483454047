*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-gray400);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray300);
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar {
  position: absolute;
  width: 6px;
}
