#root {
  background-color: var(--color-white);
  --shiji-blue: #387daf;
}
#root .map-body-container {
  display: block;
}

#root .map-header-container {
  z-index: 1;
}

#root .map-footer-rights {
  position: absolute;
  width: 100%;
  text-align: center;
}
