.regions-region-button-eu {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.regions-region-button-ap {
  position: absolute;
  top: 65%;
  left: 80%;
  transform: translate(-50%, -50%);
}

.regions-region-button-cn {
  position: absolute;
  top: 50%;
  left: 77%;
  transform: translate(-50%, -50%);
}

.regions-region-button-au {
  position: absolute;
  top: 82%;
  left: 85%;
  transform: translate(-50%, -50%);
}

.regions-region-button-na {
  position: absolute;
  top: 45%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.regions-region-button-af {
  position: absolute;
  top: 58%;
  left: 52%;
  transform: translate(-50%, -50%);
}

.regions-region-button-sa {
  position: absolute;
  top: 72%;
  left: 32%;
  transform: translate(-50%, -50%);
}

.regions-region-button-me {
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
}

.regions-region-button-us {
  position: absolute;
  top: 44%;
  left: 28%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1023px) {
  .regions-region-button .ac-button-content-line-height {
    display: none;
  }
}
