.regions-body {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1887 / 973;
  position: relative;
  max-height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.regions-body-map {
  max-height: 100%;
  width: 100%;
  height: auto;
}
