@keyframes regions-header-fade-in {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0%);
  }
}

.regions-header {
  animation: regions-header-fade-in 900ms ease;
  animation-fill-mode: forwards;
}

.regions-header-icon {
  position: relative;
  top: -20px;
  color: var(--shiji-blue);
  font-size: 55px !important;
}

.regions-header-text {
  text-align: center;
}
